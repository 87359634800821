import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/pages',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Lock',
          path: 'lock',
          component: () => import('@/views/pages/Lock')
        },
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/pages/Login')
        },
        {
          name: 'Register',
          path: 'register',
          component: () => import('@/views/pages/Register')
        }
      ]
    },
    {
      path: '/dashboard/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard')
        },
        // Pages
        {
          name: 'RTL',
          path: 'pages/rtl',
          component: () => import('@/views/dashboard/pages/Rtl')
        },
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile')
        },
        {
          name: 'Timeline',
          path: 'pages/timeline',
          component: () => import('@/views/dashboard/pages/Timeline')
        },
        // Components
        {
          name: 'Buttons',
          path: 'components/buttons',
          component: () => import('@/views/dashboard/component/Buttons')
        },
        {
          name: 'Grid System',
          path: 'components/grid-system',
          component: () => import('@/views/dashboard/component/Grid')
        },
        {
          name: 'Tabs',
          path: 'components/tabs',
          component: () => import('@/views/dashboard/component/Tabs')
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications')
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons')
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography')
        },
        // dashboard page
        {
          name: 'Branch',
          path: 'branch',
          component: () => import('@/views/dashboard/branch')
        },
      
        {
          name: 'Customer',
          path: 'customer',
          component: () => import('@/views/dashboard/customer')
        },
        {
          name: 'Setting',
          path: 'setting',
          component: () => import('@/views/dashboard/setting')
        },
        {
          name: 'Service',
          path: 'service',
          component: () => import('@/views/dashboard/service')
        },
        {
          name: 'Provider',
          path: 'provider',
          component: () => import('@/views/dashboard/provider')
        },
        {
          name: 'Profile',
          path: 'profile',
          component: () => import('@/views/dashboard/profile')
        },
        {
          name: 'Blasting',
          path: 'blasting',
          component: () => import('@/views/dashboard/blasting')
        },

      ]
    },
    {
      path: '/',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'PagesLogin',
          path: '',
          component: () => import('@/views/pages/Login')
        }
      ]
    }

  ]
})


