<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  created() {
    if (!this.getCookie("login")) {
      this.$router.push("/");
    }
    else{
      this.$router.push("./dashboard");
    }
  },

  methods:{
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
  }
};
</script>




<style lang="scss">
@import "./assets/css/app";
</style>